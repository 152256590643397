import React from "react";
import Header from "../components/Header";

function CookiePolicy () {
  return (
    <div>
      <Header />
      <div className="privacy-page-container">
        <h1>COOKIE POLICY</h1>
        <p>Last updated February 22, 2024</p>

        <p>
          This Cookie Policy explains how SkipSpace Limited ("
          <span class="bold-privacy">Company</span>," "
          <span class="bold-privacy">we</span>," "
          <span class="bold-privacy">us</span>," and "
          <span class="bold-privacy">our</span>") may use cookies and similar
          technologies to recognize you when you visit our website at{" "}
          <a href="https://www.skipspace.co.uk">https://www.skipspace.co.uk</a>{" "}
          ("<span class="bold-privacy">Website</span>") and mobile application
          SkipSpace, or any other application of ours that links to this cookie
          policy. It explains what these technologies are and why we use them,
          as well as your rights to control our use of them.
        </p>

        <p>
          In some cases, we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information.
        </p><br></br>

        <h3>What are cookies?</h3>

        <p>
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners to make their websites work or to work more
          efficiently, as well as to provide reporting information.
        </p>

        <p>
          Cookies set by the website and mobile application owner (in this case,
          SkipSpace Limited) are called "first-party cookies." Cookies set by
          parties other than the website owner are called "third-party cookies."
          Third-party cookies enable third-party features or functionality to be
          provided on or through the website (e.g., advertising, interactive
          content, and analytics). The parties that set these third-party
          cookies can recognize your computer both when it visits the website in
          question and also when it visits certain other websites.
        </p><br></br>

        <h3>Why do we use cookies?</h3>

        <p>
          We use first- and third-party cookies for several reasons. Our cookies
          are required for technical reasons in order for our Website and Mobile
          Application to operate, and we refer to these as "essential" or
          "strictly necessary" cookies.
        </p><br></br>

        <h3>How can I control cookies?</h3>

        <p>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          Cookie Consent Manager. The Cookie Consent Manager allows you to
          select which categories of cookies you accept or reject. Essential
          cookies cannot be rejected as they are strictly necessary to provide
          you with services.
        </p>

        <p>
          The Cookie Consent Manager can be found in the notification banner and
          on our website. If you choose to reject cookies, you may still use our
          website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend your web browser
          controls to accept or refuse cookies.
        </p>

        <p>
          The specific types of first- and third-party cookies served through
          our Website and the purposes they perform are solely for technical and
          operational purposes.
        </p><br></br>

        <h3>How can I control cookies on my browser?</h3>

        <p>
          As the means by which you can refuse cookies through your web browser
          controls vary from browser to browser, you should visit your browser's
          help menu for more information. The following is information about how
          to manage cookies on the most popular browsers:
        </p>

        <ul>
          <li><a target="blank" href="https://support.google.com/chrome/answer/95647#zippy%3D%2Callow-or-block-cookies">Chrome</a></li>
          <li><a target="blank" href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a></li>
          <li><a target="blank" href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Firefox</a></li>
          <li><a target="blank" href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">Safari</a></li>
          <li><a target="blank" href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Edge</a></li>
          <li><a target="blank" href="https://help.opera.com/en/latest/web-preferences/">Opera</a></li>
        </ul>

        <p>
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit:
        </p>

        <ul>
          <li>
            <a target="blank" href="https://digitaladvertisingalliance.org">
              Digital Advertising Alliance
            </a>
          </li>
          <li>
            <a target="blank" href="https://youradchoices.ca">
              Digital Advertising Alliance of Canada
            </a>
          </li>
          <li>
            <a target="blank" href="https://www.edaa.eu">
              European Interactive Digital Advertising Alliance
            </a>
          </li>
        </ul>
        <br></br>
        <h3>Do you use Flash cookies or Local Shared Objects?</h3>

        <p>
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention, and for
          other site operations.
        </p>

        <p>
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the <a target="blank" href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
          Website Storage Settings
          Panel
            </a>. You can also control Flash Cookies by going to the <a target="blank" href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html">
            Global
          Storage Settings Panel
            </a> and following the instructions (which may
          include instructions that explain, for example, how to delete existing
          Flash Cookies (referred to "information" on the Macromedia site), how
          to prevent Flash LSOs from being placed on your computer without your
          being asked, and (for Flash Player 8 and later) how to block Flash
          Cookies that are not being delivered by the operator of the page you
          are on at the time).
        </p>

        <p>
          Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.
        </p><br></br>

        <h3>How often will you update this Cookie Policy?</h3>

        <p>
          We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal, or regulatory reasons. Please, therefore, revisit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies.
        </p>

        <p>
          The date at the top of this Cookie Policy indicates when it was last
          updated.
        </p><br></br>

        <h3>Where can I get further information?</h3>

        <p>
          If you have any questions about our use of cookies or other
          technologies, please email us at hello@skipspace.co.uk
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
