// Import necessary modules and components
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { db } from "../components/firebase";
import {
  doc,
  getDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import Modal from "react-modal";
import SignOutButton from "../components/SignOutButton";

// Define the main component
function CouncilAccountDetails() {
  // Initialize Firebase authentication and navigate hook
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const handleSignOut = () => {
    navigate("/skipspace-portal");
  };

  // State for council data and delete confirmation modal
  const [councilData, setCouncilData] = useState({
    council_name: "",
    email: "",
    council_contact_firstName: "",
    council_contact_lastName: "",
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(""); // New state for current password
  const [reauthenticationError, setReauthenticationError] = useState(""); // State for reauthentication error

    // Add a state to track whether the user is authenticated
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  // Delete account handler with reauthentication
  const handleDeleteAccount = async () => {
    try {
      // Reauthenticate user with the current password
      const credentials = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credentials);

      // Continue with account deletion
      const userCouncilBorough = user.displayName;
      const councilBoroughRef = doc(db, "councils", userCouncilBorough);

      // Call the recursive function to delete the document and its subcollections
      await deleteDocumentAndSubcollections(councilBoroughRef);

      // Delete user from authentication
      await deleteUser(user);

      // After deletion, you can add further actions like signing out
      navigate("/skipspace-portal");
    } catch (error) {
      console.error("Error deleting account:", error);
      setReauthenticationError("Incorrect Password");
    }
  };

  // Recursive function to delete document and subcollections
  const deleteDocumentAndSubcollections = async (docRef) => {
    // Fetch subcollections
    const subcollectionsSnapshot = await getDocs(
      collection(docRef, "linkedSkipCompanies"),
      collection(docRef, "skipSites")
    );
    const subcollectionRefs = subcollectionsSnapshot.docs.map((doc) => doc.ref);

    // Recursively delete subcollections and their documents
    for (const subcollectionRef of subcollectionRefs) {
      await deleteDocumentAndSubcollections(subcollectionRef);
    }

    // Fetch documents within the document's collection
    const linkedSkipCompaniesSnapshot = await getDocs(
      collection(docRef, "linkedSkipCompanies")
    );
    const skipSitesSnapshot = await getDocs(collection(docRef, "skipSites"));

    const linkedSkipCompaniesRefs = linkedSkipCompaniesSnapshot.docs.map(
      (doc) => doc.ref
    );
    const skipSitesRefs = skipSitesSnapshot.docs.map((doc) => doc.ref);

    const documentRefs = [...linkedSkipCompaniesRefs, ...skipSitesRefs];

    for (const documentRef of documentRefs) {
      await deleteDoc(documentRef);
    }

    // Delete the document itself
    await deleteDoc(docRef);
  };

  // Open and close delete confirmation modal
  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setReauthenticationError(""); // Clear reauthentication error when closing the modal
  };

    // Fetch council data on component mount
    useEffect(() => {
      // Use onAuthStateChanged to listen for changes in the authentication state
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // Set the user as authenticated
          setIsUserAuthenticated(true);
  
          const userCouncilBorough = user.displayName;
          const councilBoroughRef = doc(db, "councils", userCouncilBorough);
  
          getDoc(councilBoroughRef)
            .then((docSnapshot) => {
              if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                setCouncilData(data);
              } else {
                console.error("Council data not found");
              }
            })
            .catch((error) => {
              console.error("Error fetching council data:", error);
            });
        } else {
          // Set the user as not authenticated
          setIsUserAuthenticated(false);
        }
      });
  
      return () => {
        // Unsubscribe from the onAuthStateChanged listener when the component unmounts
        unsubscribe();
      };
    }, [auth]);
  
    // If the user is not authenticated, you can redirect them to the login page or take appropriate action
    if (!isUserAuthenticated) {
      navigate("/login"); // Replace with the appropriate route
      return null;
    }

  return (
    <div className="council-account-details">
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
          <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="council-acc-details-page-container">
        <div className="back-to-dashboard">
          <Link className="text-underline" to="/skipspace-portal-dashboard">
            <p>
              {" "}
              <span style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </span>
              Back to dashboard
            </p>
          </Link>
        </div>
        <div className="council-details" style={{ paddingTop: "25px" }}>
          <h1>User Account Details</h1>
          <div style={{ paddingBottom: "8px", textTransform: "capitalize" }}>
            Council Borough: {councilData.council_name}
          </div>
          <div style={{ paddingBottom: "8px", textTransform: "capitalize" }}>
            First Name: {councilData.council_contact_firstName}
          </div>
          <div style={{ paddingBottom: "8px", textTransform: "capitalize" }}>
            Last Name: {councilData.council_contact_lastName}
          </div>
          <div style={{ paddingBottom: "8px" }}>
            Email: {auth.currentUser.email}
          </div>
        </div>
        <div style={{ paddingTop: "25px" }}>
          <Link to="/change-profile-name">
            <button className="portal-button">Change Profile Name</button>
          </Link>
        </div>
        <div style={{ paddingTop: "25px" }}>
          <Link to="/change-email">
            <button className="portal-button">Change Email</button>
          </Link>
        </div>
        <div style={{ paddingTop: "25px" }}>
          <Link to="/change-password">
            <button className="portal-button">Change Password</button>
          </Link>
        </div>
        <div style={{ paddingTop: "250px" }}>
          <p className="delete-account-text" onClick={openDeleteConfirmation}>
            Delete Account
          </p>
        </div>

        <Modal
          isOpen={showDeleteConfirmation}
          onRequestClose={closeDeleteConfirmation}
          contentLabel="Delete Confirmation Modal"
          className="modal-content"
          overlayClassName="modal"
        >
          <div className="delete-account-modal">
          <h1 style={{ marginBottom: "25px" }}>
            Please re-enter your password to confirm deletion:
          </h1>
          {/* Input field for current password */}
          <div style={{ marginBottom: "15px" }}>
            <label>
              Current Password:
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </label>
          </div>

          {/* Display reauthentication error message */}
          {reauthenticationError && (
            <div style={{ color: "red", marginBottom: "15px" }}>
              {reauthenticationError}
            </div>
          )}

          <div style={{ marginTop: "15px" }}>
            <button
              className="delete-confirm-button-yes"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
            <button
              className="delete-confirm-button-no"
              onClick={closeDeleteConfirmation}
            >
              Cancel
            </button>
          </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default CouncilAccountDetails;