import React, { useState } from 'react';
import { Register, SignIn } from '../components';
import Header from '../components/Header';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  minHeight: '85svh',
  paddingTop: '50px',
  paddingBottom:'50px',
};

const linkContainerStyle = {
  textAlign: 'center',
  padding: '25px',
};

const linkStyle = {
  cursor: 'pointer',
  fontSize: '40px',
  margin: '10px',
  textDecoration: 'none',
  color: '#000000',
  fontFamily:'tungsten-semibold'
};

const activeLinkStyle = {
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
  textDecorationColor:'rgb(122, 204, 0)',
};

function Council() {
  const [activeTab, setActiveTab] = useState('signIn');

  const switchToRegister = () => {
    setActiveTab('register');
  };

  const switchToSignIn = () => {
    setActiveTab('signIn');
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <div style={linkContainerStyle}>
          <span
            style={activeTab === 'register' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
            onClick={switchToRegister}
          >
            Register
          </span>
          <span
            style={activeTab === 'signIn' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
            onClick={switchToSignIn}
          >
            Sign In
          </span>
        </div>
        <div>
          {activeTab === 'signIn' ? (
            <SignIn switchToRegister={switchToRegister} />
          ) : (
            <Register />
          )}
        </div>
      </div>
    </div>
  );
}

export default Council;
