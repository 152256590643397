import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import noSkipCompanyAvailable from "../images/SkipCompanyPlaceholder/SKP_32653 SkipSpace App - Illustrations-Skip Empty V1.png";
import { db } from "../components/firebase";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignOutButton from "../components/SignOutButton";

function SkipCompanyDetails() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [skipCompanies, setSkipCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vouchersIssued, setVouchersIssued] = useState(0);
  const [vouchersUsed, setVouchersUsed] = useState(0); // eslint-disable-next-line
  const [councilName, setCouncilName] = useState(""); 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  useEffect(() => {
    if (user) {
      const skipCompaniesRef = collection(db, "skipCompanies");

      const q = query(skipCompaniesRef);

      getDocs(q)
        .then((querySnapshot) => {
          const skipCompaniesData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the document has data
            if (Object.keys(data).length > 0) {
              skipCompaniesData.push({
                id: doc.id,
                ...data,
              });
            }
          });
          setSkipCompanies(skipCompaniesData);
          setLoading(false); // Set loading to false once data is fetched
        })
        .catch((error) => {
          console.error("Error fetching skip companies:", error);
          setLoading(false); // Set loading to false on error as well
        });
    }
  }, [user]);
   // Utility function to fetch council data by council_name
   const fetchCouncilDataByName = async (councilName) => {
    const councilDocRef = doc(db, "councils", councilName);
    const councilDocSnapshot = await getDoc(councilDocRef);

    if (councilDocSnapshot.exists()) {
      const data = councilDocSnapshot.data();
      return data.council_name;
    }

    return null;
  };

  const handleSignOut = () => {
    navigate("/skipspace-portal");
  };

  return (
    <div className="view-skip-company-details">
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
          <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="view-skip-company-details-page-container">
        <div className="back-to-dashboard">
          <Link className="text-underline" to="/skipspace-portal-dashboard">
            <p>
              {" "}
              <span style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </span>
              Back to dashboard
            </p>
          </Link>
        </div>
        {loading ? (
          <div className="loading-message"></div>
        ) : skipCompanies.length === 0 ? (
          <div style={{ textAlign: "center", paddingTop: "25px" }}>
            <h1>There are no skip companies</h1>
            <img
              style={{ maxWidth: "100%", width: "25rem" }}
              src={noSkipCompanyAvailable}
              alt="NoSkipCompanyAvailable"
            />
          </div>
        ) : (
          <>
            <h1 style={{ padding: "50px 0px 25px 0px", textDecoration:"underline", textUnderlinePosition:"under"}}> Registered Skip Companies:</h1>
            {skipCompanies.map((company, index) => (
              <div
                key={company.id}
                className={`company-section ${
                  index === skipCompanies.length - 1 ? "last-section" : ""
                }`}
              >
                <div className="left-column">
                  <h1>{company["skip_company_name"]}</h1>
                  <div>First Name: {company["contact_first_name"]}</div>
                  <div>Last Name: {company["contact_last_name"]}</div>
                  <div>Contact Number: {company["contact_number"]}</div>
                  <div>Contact Email: {company["contact_email"]}</div>
                  <div>Address: {company["skip_company_address"]}</div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SkipCompanyDetails;

/*

   useEffect(() => {
    const fetchData = async () => {
      try {
        const councilNameValue = await fetchCouncilDataByName(
          auth.currentUser.displayName
        );
        if (councilNameValue) {
          setCouncilName(councilNameValue);

          // Now that we have the council name, let's fetch voucher data
          const vouchersRef = collection(db, "vouchers");

          const vouchersQuery = query(
            vouchersRef,
            where("local_auth_issue", "==", councilNameValue),
          );

          const querySnapshot = await getDocs(vouchersQuery);
          setVouchersIssued(querySnapshot.size);

          // Now fetch vouchers used count
          const vouchersUsedQuery = query(
            vouchersRef,
            where("local_auth_issue", "==", councilNameValue), 
            where("voucher_used", "==", true)
          );

          const usedQuerySnapshot = await getDocs(vouchersUsedQuery);
          setVouchersUsed(usedQuerySnapshot.size);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.currentUser]);

  <h1 className="vouchers-issued" >Vouchers Issued: {vouchersIssued}</h1>
            <h1 className="vouchers-used" >Vouchers Used: {vouchersUsed}</h1>
            */
