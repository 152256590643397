import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";

const VerifyEmail = ({ email }) => {
  const auth = getAuth(); // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [verificationError, setVerificationError] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(false);

      // Check if the user exists and email matches registered email
      if (user && user.email === location.state?.registeredEmail) {
        await checkEmailVerificationStatus(user);
      }
    });

    return () => unsubscribe();
  }, );

  useEffect(() => {
    // Start polling for email verification status
    const intervalId = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        await user.reload(); // Reload the user to get the updated email verification status
        await checkEmailVerificationStatus(user);
      }
    }, 3000); // Poll every 3 seconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, );

  const checkEmailVerificationStatus = async (user) => {
    try {
      if (user.emailVerified) {
        navigate("/skipspace-portal"); // Redirect if email is verified
      }
    } catch (error) {
      console.error("Error checking email verification:", error.message);
      setVerificationError(
        "Error checking email verification. Please try again."
      );
    }
  };

  const handleResendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        await sendEmailVerification(user);
        setConfirmationMessage("A new email has been sent!");
      }
    } catch (error) {
      console.error('Error sending verification email: ', error);

      if (error.code === 'auth/too-many-requests') {
        // Implement exponential backoff if the error is due to too many requests
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
        await handleResendVerificationEmail(); // Retry
      }
    }
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const contentStyle = {
    textAlign: "center",
  };

  const logoStyle = {
    maxWidth: "200px",
    height: "auto",
    marginBottom: "20px",
  };

  const spinnerStyle = {
    fontSize: "2em",
    color: "#003A49",
    marginBottom: "20px",
  };

  const textStyle = {
    fontSize: "2rem",
    marginBottom: "20px",
  };

  const resendTextStyle = {
    fontSize: "1rem",
    color: "#003A49",
    textDecoration: "underline",
    cursor: "pointer",
    marginBottom: "10px",
  };

  const confirmationMessageStyle = {
    fontSize: "1rem",
    color: "#7acc00",
    marginTop: "10px",
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <img src={skipspaceLogo} alt="Logo" style={logoStyle} />

        <div style={spinnerStyle}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>

        <h1 style={textStyle}>Please verify your email...</h1>

        <div 
          style={resendTextStyle}
          onClick={handleResendVerificationEmail}
        >
          Resend verification email
        </div>

        {confirmationMessage && (
          <div style={confirmationMessageStyle}>
            {confirmationMessage}
          </div>
        )}

        {verificationError && (
          <div style={{ color: "red", marginTop: "10px" }}>
            {verificationError}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
