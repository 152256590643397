import React from 'react';
import { useNavigate } from 'react-router-dom';
import skipspaceLogo from '../images/Logo/skipspace_logo.svg';

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100svh',
    minHeight: '400px', 
    textAlign: 'center',
  };

  const logoStyle = {
    maxWidth: '200px',
    width: '100%',
    height: 'auto',
    marginBottom: '40px',
  };

  return (
    <div style={containerStyle}>
      <img src={skipspaceLogo} alt="Logo" style={logoStyle} />
      <h1>Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button className='portal-button' onClick={goBack}>Go Back</button>
    </div>
  );
};

export default NotFound;
