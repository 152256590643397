import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getAuth, signOut } from "firebase/auth";

const signOutButtonStyle = {
  backgroundColor: "rgb(122, 204, 0)",
  color: "#003A49",
  fontFamily: "Tungsten-medium",
  fontSize: "1.3rem",
  borderRadius: "5px",
  border: "none",
  padding: "10px 30px",
};

const auth = getAuth();

const SignOutButton = ({ onSignOut }) => {
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);

  // Set app element before rendering the modal
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const handleLogout = () => {
    setIsSignOutModalOpen(true);
  };

  const handleConfirmLogout = async () => {
    try {
      await signOut(auth);
      setIsSignOutModalOpen(false);
      onSignOut(); // Call the parent component's onSignOut function
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleCancelLogout = () => {
    setIsSignOutModalOpen(false);
  };

  return (
    <div>
      <button onClick={handleLogout} style={{ ...signOutButtonStyle }}>
        Sign Out
      </button>
      {/* Sign Out Modal */}
      <Modal
        isOpen={isSignOutModalOpen}
        onRequestClose={handleCancelLogout}
        className="modal-content"
        contentLabel="Sign Out Confirmation"
      >
        <h1>Are you sure you want to sign out?</h1>
        <div style={{ marginTop: "15px" }}>
          <button
            className="sign-out-confirm-button-yes"
            onClick={handleConfirmLogout}
          >
            Yes
          </button>
          <button
            className="sign-out-confirm-button-no"
            onClick={handleCancelLogout}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SignOutButton;
