import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import SignOutButton from "../components/SignOutButton";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { db } from "../components/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Modal from "react-modal";

library.add(faTimes);

// Set the root element for the modal
Modal.setAppElement("#root");

function SkipSiteAddress() {
  const navigate = useNavigate();
  const [siteValues, setSiteValues] = useState({});
  const [siteCount, setSiteCount] = useState(1);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true); // New state
  const auth = getAuth();

  // Modal state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteFieldName, setDeleteFieldName] = useState("");

  const handleSignOut = () => {
    navigate("/skipspace-portal");
  };

  useEffect(() => {
    // Check user authentication state
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const userCouncilBorough = user.displayName;
          const localCouncilsRef = collection(db, "councils");
          const councilBoroughRef = doc(localCouncilsRef, userCouncilBorough);
          const skipSitesColRef = collection(councilBoroughRef, "skipSites");

          // Fetch all documents in the "skipSites" collection
          const querySnapshot = await getDocs(skipSitesColRef);

          // Initialize an object to store site values
          const siteValues = {};

          // Loop through each document and extract the address field
          querySnapshot.forEach((doc) => {
            const fieldName = doc.id;
            const addressValue = doc.data().address;
            siteValues[fieldName] = addressValue;
          });

          setSiteValues(siteValues);
          setSiteCount(Object.keys(siteValues).length);
        }
      } catch (error) {
        console.error("Error fetching existing sites:", error);
      } finally {
        setLoading(false);
        setInitialLoad(false); // Set initialLoad to false after fetching data
      }
    });

    // Unsubscribe to onAuthStateChanged when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  const handleSiteChange = (fieldName, value) => {
    setSiteValues((prevSiteValues) => ({
      ...prevSiteValues,
      [fieldName]: value,
    }));
  };

  const handleAddSite = () => {
    setSiteCount((prevCount) => prevCount + 1);
  };

  const handleAddEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userCouncilBorough = user.displayName;
        const localCouncilsRef = collection(db, "councils");
        const councilBoroughRef = doc(localCouncilsRef, userCouncilBorough);
        const skipSitesColRef = collection(
          councilBoroughRef,
          "skipSites"
        );

        // Create a new document for each site
        for (let i = 1; i <= siteCount; i++) {
          const siteDocRef = doc(skipSitesColRef, `site${i}`);
          const addressField = siteValues[`site${i}`];

          // Check if the site already exists
          const siteDoc = await getDoc(siteDocRef);
          if (siteDoc.exists()) {
            // Update existing site document
            await setDoc(siteDocRef, { address: addressField });
          } else {
            // Create a new site document
            await setDoc(siteDocRef, { address: addressField });
          }
        }

        setUpdateSuccessMessage("Update successful!");
      }
    } catch (error) {
      console.error("Error updating sites:", error);
    }
  };

  const handleDeleteSite = async (fieldName) => {
    try {
      setDeleteFieldName(fieldName);
      setShowConfirmationModal(true);
    } catch (error) {
      console.error("Error initiating delete:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userCouncilBorough = user.displayName;
        const localCouncilsRef = collection(db, "councils");
        const councilBoroughRef = doc(localCouncilsRef, userCouncilBorough);
        const skipSitesColRef = collection(
          councilBoroughRef,
          "skipSites"
        );

        const siteDocRef = doc(skipSitesColRef, deleteFieldName);

        // Fetch the document to be deleted
        const siteDoc = await getDoc(siteDocRef);

        if (siteDoc.exists()) {
          // Delete the specific site document
          await deleteDoc(siteDocRef);

          // Update the remaining site fields to maintain order
          const updatedSiteValues = { ...siteValues };
          delete updatedSiteValues[deleteFieldName];

          // Renumber all remaining sites
          const renumberedSiteValues = {};
          const sortedKeys = Object.keys(updatedSiteValues).sort((a, b) => {
            const aIndex = parseInt(a.replace("site", ""));
            const bIndex = parseInt(b.replace("site", ""));
            return aIndex - bIndex;
          });

          sortedKeys.forEach((key, index) => {
            renumberedSiteValues[`site${index + 1}`] =
              updatedSiteValues[key];
          });

          setSiteValues(renumberedSiteValues);
          setSiteCount(Object.keys(renumberedSiteValues).length);
          setUpdateSuccessMessage("Site deleted successfully!");

          // Remove the obsolete site document from the database
          const obsoleteSiteDocRef = doc(
            skipSitesColRef,
            `site${siteCount}`
          );
          await deleteDoc(obsoleteSiteDocRef);

          // Update the renumbered values in the database
          for (const [newKey, value] of Object.entries(
            renumberedSiteValues
          )) {
            const newSiteDocRef = doc(skipSitesColRef, newKey);
            await setDoc(newSiteDocRef, { address: value });
          }
        }
      }
    } catch (error) {
      console.error("Error deleting site:", error);
    } finally {
      // Reset modal state after deletion
      setShowConfirmationModal(false);
      setDeleteFieldName("");
    }
  };

  const shouldDisplayUpdateButton =
    Object.keys(siteValues).length > 0 || siteCount > 1;

  return (
    <div>
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
          <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="link-skip-company-page-container">
        <div className="back-to-dashboard">
          <Link className="text-underline" to="/skipspace-portal-dashboard">
            <p>
              {" "}
              <span style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </span>
              Back to dashboard
            </p>
          </Link>
        </div>
        <div>
          {loading && initialLoad && (
            <h1> </h1>
          )}

          {!loading && !initialLoad && (Object.keys(siteValues).length === 0 && siteCount <= 1) && (
            <h1>Please add site below</h1>
          )}

          {!loading && !initialLoad && siteValues && (
            <div>
              <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                {Array.from({ length: siteCount }).map((_, index) => (
                  <div
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    key={index}
                  >
                    <label htmlFor={`site${index + 1}`}>{`Site ${
                      index + 1
                    }:`}</label>
                    <input
                      style={{
                        outline: "none",
                        border: "solid 1px #003A49",
                        borderRadius: "5px",
                        padding: "5px",
                        marginLeft: "5px",
                      }}
                      type="text"
                      id={`site${index + 1}`}
                      name={`site${index + 1}`}
                      value={siteValues[`site${index + 1}`] || ""}
                      onChange={(e) =>
                        handleSiteChange(`site${index + 1}`, e.target.value)
                      }
                    />
                    {siteValues[`site${index + 1}`] && (
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          fontSize: "20px",
                        }}
                        className="delete-button"
                        onClick={() =>
                          handleDeleteSite(`site${index + 1}`)
                        }
                      >
                        <FontAwesomeIcon icon="times" style={{ color: "red" }} />
                      </button>
                    )}
                  </div>
                ))}
              </div>

              {shouldDisplayUpdateButton && (
                <div>
                  <button className="portal-button" onClick={handleAddEmail}>
                    Update
                  </button>
                </div>
              )}
              <button className="portal-button" onClick={handleAddSite}>
                Add Site
              </button>
            </div>
          )}

          {updateSuccessMessage && (
            <div>
              <p style={{ color: "green" }}>{updateSuccessMessage}</p>
            </div>
          )}
        </div>
      </div>

      {/* Delete confirmation modal */}
      <Modal
        isOpen={showConfirmationModal}
        onRequestClose={() => setShowConfirmationModal(false)}
        contentLabel="Delete Confirmation"
      >
        <h2>Are you sure you want to delete this site?</h2>
        <div style={{ display: "inline-block" }}>
          <button
            className="delete-site-button-yes"
            onClick={handleConfirmDelete}
          >
            Yes
          </button>
          <button
            className="delete-site-button-no"
            onClick={() => setShowConfirmationModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default SkipSiteAddress;
