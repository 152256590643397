import React from "react";
import Header from "../components/Header";

function Contact() {
  const handleSendMessage = () => {
    window.location.href = "mailto:hellok@skipspace.co.uk";
  };

  return (
    <div>
      <Header />
      <div className="contact-page-container">
        <h1 style={{ paddingBottom: "25px" }}>Contact Us</h1>
        <p style={{ textAlign: "center", paddingBottom: "15px" }}>
          Our dedicated customer support team is ready to assist you with any
          inquiries you may have about SkipSpace.
        </p>
        <p style={{ textAlign: "center", paddingBottom: "15px" }}>
          Whether you're curious about its features, need guidance on how it can
          benefit your community, or require technical assistance, we are here
          for you.
        </p>
        <p style={{ textAlign: "center", paddingBottom: "15px" }}>
          If you're looking to update your account or request for deletion, please enquire using the email below.
        </p>
        <p style={{ textAlign: "center", paddingBottom: "15px" }}>
          Your commitment to combatting illegal waste disposal aligns with our
          mission, and we look forward to supporting your efforts with the
          SkipSpace App.
        </p>
        <p>
          Contact us via email: <span className="kirk">hello@skipspace.co.uk</span>
        </p>
      </div>
    </div>
  );
}

export default Contact;
