import React from "react";
import Header from "../components/Header";

function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <div className="privacy-page-container">
        <h1>PRIVACY POLICY</h1>
        <p>Last updated February 22, 2024</p>

        <p>
          This privacy notice for SkipSpace Limited (doing business as
          SkipSpace) ('<span class="bold-privacy">we</span>', '
          <span class="bold-privacy">us</span>', or '
          <span class="bold-privacy">our</span>'), describes how and why we
          might collect, store, use, and/or share ('
          <span class="bold-privacy">process</span>') your information when you
          use our services ('<span class="bold-privacy">Services</span>'), such
          as when you:
        </p>

        <ul>
          <li>
            Visit our website at{" "}
            <a href="https://www.skipspace.co.uk">
              https://www.skipspace.co.uk
            </a>
            , or any website of ours that links to this privacy notice
          </li>
          <li>
            Download and use our mobile application (SkipSpace), or any other
            application of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>

        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          hello@skipspace.co.uk.
        </p>
        <br></br>

        <h2>SUMMARY OF KEY POINTS</h2>

        <p class="bold-italic-privacy">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our{" "}
          <a href="#table-of-contents">table of contents</a> below to find the
          section you are looking for.
        </p>

        <p>
          <span class="bold-privacy">
            What personal information do we process?
          </span>{" "}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about{" "}
          <a href="#what-info-do-we-collect">
            personal information you disclose to us
          </a>
          .
        </p>

        <p>
          <span class="bold-privacy">
            Do we process any sensitive personal information?
          </span>{" "}
          We do not process sensitive personal information.
        </p>

        <p>
          <span class="bold-privacy">
            Do we receive any information from third parties?
          </span>{" "}
          We do not receive any information from third parties.
        </p>

        <p>
          <span class="bold-privacy">How do we process your information?</span>{" "}
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about{" "}
          <a href="#how-do-we-process-your-information">
            how we process your information
          </a>
          .
        </p>

        <p>
          <span class="bold-privacy">
            In what situations and with which types of parties do we share
            personal information?
          </span>{" "}
          We may share information in specific situations and with specific
          categories of third parties. Learn more about{" "}
          <a href="#when-and-with-whom-do-we-share">
            when and with whom we share your personal information
          </a>
          .
        </p>

        <p>
          <span class="bold-privacy">
            How do we keep your information safe?
          </span>{" "}
          We have organisational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorised third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about{" "}
          <a href="#how-do-we-keep-your-info-safe">
            how we keep your information safe
          </a>
          .
        </p>

        <p>
          <span class="bold-privacy">What are your rights?</span> Depending on
          where you are located geographically, the applicable privacy law may
          mean you have certain rights regarding your personal information.
          Learn more about{" "}
          <a href="#your-privacy-rights">your privacy rights</a>.
        </p>

        <p>
          <span class="bold-privacy">How do you exercise your rights?</span> The
          easiest way to exercise your rights is by visiting{" "}
          <a href="https://www.skipspace.co.uk/contact">
            https://www.skipspace.co.uk/contact
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what we do with any information we collect?{" "}
          <a href="#table-of-contents">Review the privacy notice in full</a>.
        </p>
        <br id="table-of-contents"></br>

        <h2>TABLE OF CONTENTS</h2>

        <ol>
          <li>
            <a href="#what-info-do-we-collect">
              WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a href="#how-do-we-process-your-information">
              HOW DO WE PROCESS YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#what-legal-bases-we-rely-on">
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </a>
          </li>
          <li>
            <a href="#when-and-with-whom-do-we-share">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a href="#do-we-use-cookies">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#how-long-do-we-keep-your-info">
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#how-do-we-keep-your-info-safe">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>
          </li>
          <li>
            <a href="#do-we-collect-info-from-minors">
              DO WE COLLECT INFORMATION FROM MINORS?
            </a>
          </li>
          <li>
            <a href="#your-privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#controls-for-do-not-track-features">
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a href="#do-we-make-updates-to-this-notice">
              DO WE MAKE UPDATES TO THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#how-can-you-contact-us-about-this-notice">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#how-can-you-review-update-delete-data-we-collect">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ol>
        <br id="what-info-do-we-collect"></br>

        <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>

        <p className="bold-sub-heading-privacy">
          Personal information you disclose to us
        </p>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We collect
          personal information that you provide to us.
        </p>

        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>

        <p>
          <span className="bold-privacy">
            Personal Information Provided by You.
          </span>{" "}
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>

        <ul>
          <li>email addresses</li>
          <li>usernames</li>
          <li>phone numbers</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
          <li>names</li>
          <li>passwords</li>
        </ul>

        <p>
          <span className="bold-sub-heading-privacy">
            Sensitive Information.
          </span>{" "}
          We do not process sensitive information.
        </p>

        <p>
          <span className="bold-sub-heading-privacy">Application Data.</span> If
          you use our application(s), we also may collect the following
          information if you choose to provide us with access or permission:
        </p>

        <ul>
          <li>
            Geolocation Information. We may request access or permission to
            track location-based information from your mobile device, either
            continuously or while you are using our mobile application(s), to
            provide certain location-based services. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </li>
        </ul>

        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>

        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <p className="bold-sub-heading-privacy">
          Information automatically collected
        </p>

        <p>
          <span className="bold-italic-privacy">In Short</span> : Some
          information — such as your Internet Protocol (IP) address and/or
          browser and device characteristics — is collected automatically when
          you visit our Services.
        </p>

        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>

        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>

        <p>The information we collect includes:</p>

        <ul>
          <li>
            Location Data. We collect location data such as information about
            your device's location, which can be either precise or imprecise.
            How much information we collect depends on the type and settings of
            the device you use to access the Services. For example, we may use
            GPS and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </li>
        </ul>

        <br id="how-do-we-process-your-information"></br>

        <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We process
          your information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent.
        </p>

        <p className="bold-privacy">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>

        <ul>
          <li>
            <span className="bold-privacy">
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </span>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">
              To deliver and facilitate delivery of services to the user.
            </span>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">
              To respond to user inquiries/offer support to users.
            </span>{" "}
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">
              To send administrative information to you.
            </span>{" "}
            We may process your information to send you details about our
            products and services, changes to our terms and policies, and other
            similar information.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">
              To save or protect an individual's vital interest.
            </span>{" "}
            We may process your information when necessary to save or protect an
            individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
        <br id="what-legal-bases-we-rely-on"></br>

        <h3>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We only
          process your personal information when we believe it is necessary and
          we have a valid legal reason (i.e. legal basis) to do so under
          applicable law, like with your consent, to comply with laws, to
          provide you with services to enter into or fulfil our contractual
          obligations, to protect your rights, or to fulfil our legitimate
          business interests.
        </p>

        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>

        <ul>
          <li>
            <span className="bold-privacy">Consent.</span> We may process your
            information if you have given us permission (i.e. consent) to use
            your personal information for a specific purpose. You can withdraw
            your consent at any time. Learn more about withdrawing your consent.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">Performance of a Contract.</span> We
            may process your personal information when we believe it is
            necessary to fulfil our contractual obligations to you, including
            providing our Services or at your request prior to entering into a
            contract with you.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">Legal Obligations.</span> We may
            process your information where we believe it is necessary for
            compliance with our legal obligations, such as to cooperate with a
            law enforcement body or regulatory agency, exercise or defend our
            legal rights, or disclose your information as evidence in litigation
            in which we are involved.
          </li>
          <br></br>
          <li>
            <span className="bold-privacy">Vital Interests.</span> We may
            process your information where we believe it is necessary to protect
            your vital interests or the vital interests of a third party, such
            as situations involving potential threats to the safety of any
            person.
          </li>
        </ul>

        <p>
          In legal terms, we are generally the 'data controller' under European
          data protection laws of the personal information described in this
          privacy notice, since we determine the means and/or purposes of the
          data processing we perform. This privacy notice does not apply to the
          personal information we process as a 'data processor' on behalf of our
          customers. In those situations, the customer that we provide services
          to and with whom we have entered into a data processing agreement is
          the 'data controller' responsible for your personal information, and
          we merely process your information on their behalf in accordance with
          your instructions. If you want to know more about our customers'
          privacy practices, you should read their privacy policies and direct
          any questions you have to them.
        </p>
        <br id="when-and-with-whom-do-we-share"></br>

        <h3>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We may share
          information in specific situations described in this section and/or
          with the following categories of third parties.
        </p>

        <p>
          <span className="bold-privacy">
            Vendors, Consultants, and Other Third-Party Service Providers.
          </span>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents ('
          <span className="bold-privacy">third parties</span>') who perform
          services for us or on our behalf and require access to such
          information to do that work. We have contracts in place with our third
          parties, which are designed to help safeguard your personal
          information. This means that they cannot do anything with your
          personal information unless we have instructed them to do it. They
          will also not share your personal information with any organisation
          apart from us. They also commit to protect the data they hold on our
          behalf and to retain it for the period we instruct. The categories of
          third parties we may share personal information with are as follows:
        </p>

        <ul>
          <li>Testing Tools</li>
          <li>Cloud Computing Services</li>
        </ul>

        <p>
          We also may need to share your personal information in the following
          situations:
        </p>

        <ul>
          <li>
            <span className="bold-privacy">Business Transfers.</span> We may
            share or transfer your information in connection with, or during
            negotiations of, any merger, sale of company assets, financing, or
            acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <span className="bold-privacy">
              When we use Google Maps Platform APIs.
            </span>{" "}
            We may share your information with certain Google Maps Platform APIs
            (e.g. Google Maps API, Places API).
          </li>
        </ul>
        <br id="do-we-use-cookies"></br>

        <h3>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We may use
          cookies and other tracking technologies to collect and store your
          information.
        </p>

        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our <a href="https://www.skipspace.co.uk/cookie-policy">Cookie Policy</a>.
        </p>
        <br id="how-long-do-we-keep-your-info"></br>

        <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We keep your
          information for as long as necessary to fulfil the purposes outlined
          in this privacy notice unless otherwise required by law.
        </p>

        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>

        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <br id="how-do-we-keep-your-info-safe"></br>

        <h3>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We aim to
          protect your personal information through a system of organisational
          and technical security measures.
        </p>

        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <br id="do-we-collect-info-from-minors"></br>

        <h3>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : We do not
          knowingly collect data from or market to children under 18 years of
          age.
        </p>

        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at hello@skipspace.co.uk.
        </p>
        <br id="your-privacy-rights"></br>

        <h3>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>

        <p>
          <span className="bold-italic-privacy">In Short</span> : In some
          regions, such as the European Economic Area (EEA), United Kingdom
          (UK), and Switzerland, you have rights that allow you greater access
          to and control over your personal information. You may review, change,
          or terminate your account at any time.
        </p>

        <p>
          In some regions (like the EEA, UK, and Switzerland), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section{" "}
          <a href="#how-can-you-contact-us-about-this-notice">
            'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
          </a>{" "}
          below.
        </p>

        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>

        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your <a target="blank" href="https://ec.europa.eu/newsroom/article29/items/612080">
          Member State data protection authority
          </a> or <a target="blank" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
          UK data
          protection authority
          </a>.
        </p>

        <p>
          If you are located in Switzerland, you may contact the <a target="blank" href="https://www.edoeb.admin.ch/edoeb/en/home.html">
          Federal Data
          Protection and Information Commissioner
          </a>.
        </p>

        <p>
        <span className="bold-underline-privacy">Withdrawing your consent:</span> If we are relying on your consent to process
          your personal information, you have the right to withdraw your consent
          at any time. You can withdraw your consent at any time by contacting
          us by using the contact details provided in the section{" "}
          <a href="#how-can-you-contact-us-about-this-notice">
            'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
          </a> below or updating your preferences.
        </p>

        <p>Account Information</p>

        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>

        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>

        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <p>
          If you have questions or comments about your privacy rights, you may
          email us at hello@skipspace.co.uk.
        </p>
        <br id="controls-for-do-not-track-features"></br>

        <h3>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>

        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <br id="do-we-make-updates-to-this-notice"></br>

        <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>

        <p>
        <span className="bold-italic-privacy">In Short</span> : Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>

        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <br id="how-can-you-contact-us-about-this-notice"></br>

        <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>

        <p>
          If you have questions or comments about this notice, you may email us
          at hello@skipspace.co.uk, by visiting{" "}
          <a href="https://www.skipspace.co.uk/contact">
            https://www.skipspace.co.uk/contact
          </a>
          .
        </p>

        <p>
          If you are a resident in the United Kingdom, we are the 'data
          controller' of your personal information. We have appointed Kirk
          Clugston to be our representative in the UK. You can contact them
          directly regarding our processing of your information, by email at
          hello@skipspace.co.uk, by visiting{" "}
          <a href="https://www.skipspace.co.uk/contact">
            https://www.skipspace.co.uk/contact
          </a>
          .
        </p>
        <br id="how-can-you-review-update-delete-data-we-collect"></br>

        <h3>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h3>

        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please visit:<a href="https://www.skipspace.co.uk/contact">
          https://www.skipspace.co.uk/contact</a>.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
