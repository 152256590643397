import React from 'react';
import icon1 from '../images/OurImpact/Fly-tipping.png';
import icon2 from '../images/OurImpact/Roadside Pollution.png';
import icon3 from '../images/OurImpact/Council Sites.png';
import icon4 from '../images/OurImpact/Cost.png';
import icon5 from '../images/OurImpact/Fuel.png';
import icon6 from '../images/OurImpact/Council staff.png';
import icon7 from '../images/OurImpact/Legal Costs.png';
import icon8 from '../images/OurImpact/Courtroom Hours.png';

function IconList({ items }) {
  const iconStyle = {
    width: '100px',
  };

  const listItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const textStyle = {
    marginLeft: '20px',
  };

  return (
    <ul style={{ listStyle: 'none', marginRight: '0px', padding: '0' }}>
      {items.map((item, index) => (
        <li key={index} style={listItemStyle}>
          <img src={item.icon} alt={item.text} style={iconStyle} />
          <span className="text" style={textStyle}>{item.text}</span>
        </li>
      ))}
    </ul>
  );
}

function TwoColumnIconLists() {
  
  const leftColumnItems = [
    { icon: icon1, text: 'Less fly-tipping' },
    { icon: icon2, text: 'Less roadside pollution' },
    { icon: icon6, text: 'Less council staff hours' },
    { icon: icon4, text: 'Less cost for fly-tipping clean up' },
  ];

  const rightColumnItems = [
    { icon: icon5, text: 'Less fuel used transporting fly-tipping' },
    { icon: icon7, text: 'Less legal costs spent on prosecuting offenders' },
    { icon: icon8, text: 'Less courtroom hours' },
    { icon: icon3, text: 'More waste going to council sites' },
  ];

  return (
    <div className='positiveImpactColumnStyle'>
      <div>
        <IconList items={leftColumnItems} />
      </div>
      <div>
        <IconList items={rightColumnItems} />
      </div>
    </div>
  );
}

export default TwoColumnIconLists;
