import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { db } from "./firebase";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import SignOutButton from '../components/SignOutButton';

const formStyle = {
  maxWidth: "400px",
  margin: "0 auto",
  paddingTop:"25px",
  borderRadius: "5px",
  textAlign: "center",
};

const labelStyle = {
  display: "block",
  textAlign: "left",
  marginTop: "10px",
};

const buttonStyle = {
  width: "100%",
  padding: "10px",
  backgroundColor: "#003A49",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const errorStyle = {
  color: "red",
  fontSize: "0.8em",
  textAlign: "left",
};

const CouncilRegistrationSchema = Yup.object().shape({
  council_contact_firstName: Yup.string(),
  council_contact_lastName: Yup.string(),
});

function ChangeProfileName() {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (values) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const localCouncilsRef = collection(db, "councils");
      const councilBoroughRef = doc(localCouncilsRef, user.displayName);

      // Fetch existing councilBorough value from Firestore
      const existingData = await getDoc(councilBoroughRef);
      const existingCouncilBorough = existingData.exists()
        ? existingData.data().council_name
        : "";

      // Update user profile (first name and last name)
      await updateProfile(user, {
        displayName: values.council_name,
      });
 
      // Determine the updated data
      const updatedData = {
        council_name: existingCouncilBorough,
      };

      // Update first name if it's not blank
      if (values.council_contact_firstName.trim() !== "") {
        updatedData.council_contact_firstName = values.council_contact_firstName.trim();
      } else {
        updatedData.council_contact_firstName = existingData.exists()
          ? existingData.data().council_contact_firstName
          : "";
      }

      // Update last name if it's not blank
      if (values.council_contact_lastName.trim() !== "") {
        updatedData.council_contact_lastName = values.council_contact_lastName.trim();
      } else {
        updatedData.council_contact_lastName = existingData.exists()
          ? existingData.data().council_contact_lastName
          : "";
      }

      // Update Firestore document
      await setDoc(councilBoroughRef, updatedData);

      setSuccessMessage("Profile updated successfully");
      setErrorMessage("");
      navigate("/council-account-details");
    } catch (error) {
      console.error("Error updating profile: ", error.message);
      setErrorMessage("Profile update failed. Please try again.");
      setSuccessMessage("");
    }
  };

  const [councilData, setCouncilData] = useState({
    council_name: "",
    council_contact_firstName: "",
    council_contact_lastName: "",
  });

  const handleSignOut = () => {
    navigate('/skipspace-portal');
  };

  useEffect(() => {
    if (user) {
      const userCouncilBorough = user.displayName;
      const councilBoroughRef = doc(db, "councils", userCouncilBorough);

      getDoc(councilBoroughRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setCouncilData(data);
          } else {
            console.error("Council data not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching council data:", error);
        });
    }
  }, [user]);

  return (
    <div>
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
        <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="change-profile-name-page-container">
      <div className="back-to-dashboard">
        <Link className="text-underline" to="/council-account-details">
          <p>
            {" "}
            <span style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </span>
            Back to Account Details
          </p>
        </Link>
      </div>
      <div>
        <Formik
          initialValues={{
            council_contact_firstName: councilData.council_contact_firstName || "",
            council_contact_lastName: councilData.council_contact_lastName || "",
          }}
          validationSchema={CouncilRegistrationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form style={formStyle}>
              <div>
                <label htmlFor="council_contact_firstName" style={labelStyle}>
                  Council Staff First Name
                </label>
                <Field
                  type="text"
                  name="council_contact_firstName"
                  className="registerInputStyle"
                  placeholder="Leave blank to keep the same"
                  value={values.council_contact_firstName}
                  onChange={handleChange}
                  onBlur={() => {
                    // Reset to councilData.firstName if the field is left blank
                    if (values.council_contact_firstName.trim() === "") {
                      setFieldValue("council_contact_firstName", councilData.council_contact_firstName || "");
                    }
                  }}
                  
                />
                <ErrorMessage
                  name="council_contact_firstName"
                  component="div"
                  style={errorStyle}
                />
              </div>

              <div>
                <label htmlFor="council_contact_lastName" style={labelStyle}>
                  Council Staff Last Name
                </label>
                <Field
                  type="text"
                  name="council_contact_lastName"
                  className="registerInputStyle"
                  placeholder="Leave blank to keep the same"
                  value={values.council_contact_lastName}
                  onChange={handleChange}
                  onBlur={() => {
                    // Reset to councilData.lastName if the field is left blank
                    if (values.council_contact_lastName.trim() === "") {
                      setFieldValue("council_contact_lastName", councilData.council_contact_lastName || "");
                    }
                  }}
                  
                />
                <ErrorMessage
                  name="council_contact_lastName"
                  component="div"
                  style={errorStyle}
                />
              </div>
              <button type="submit" style={buttonStyle}>
                Update Profile Name
              </button>
              {successMessage && (
                <div style={{ color: "green" }}>{successMessage}</div>
              )}
              {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      </div>
    </div>
  );
}

export default ChangeProfileName;
