import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';

const formStyle = {
  maxWidth: '400px',
  margin: '0 auto',
  padding: '20px',
  textAlign: 'left',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  backgroundColor: '#003A49',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const forgotPasswordStyle = {
  marginTop: '10px',
  textDecoration: 'none',
  color: '#000000',
};

const errorStyle = {
  color: 'red',
  fontSize: '12px',
  marginTop: '5px',
};

const registerLinkStyle = {
  cursor: 'pointer',
  fontSize: '16px',
  textDecoration: 'none',
  color: '#000000',
  marginTop: '15px',
};

const registerLinkHoverStyle = {
  textDecoration: 'underline',
};

function SignIn({ switchToRegister }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required');
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required');
    } else {
      setPasswordError('');
    }

    if (email && password) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if the email is verified
        if (user && user.emailVerified) {
          navigate('/skipspace-portal-dashboard');
        } else if (user && !user.emailVerified) {
          // If the email is not verified, display an error
          setEmailError('Email is not verified. ');
        }
      } catch (error) {
        console.error('Error signing in: ', error);
        setEmailError('Invalid email or password');
        setPasswordError('Invalid email or password');
      }
    }
  };

  const handleResendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        await sendEmailVerification(user);
      }
    } catch (error) {
      console.error('Error sending verification email: ', error);

      if (error.code === 'auth/too-many-requests') {
        // Implement exponential backoff if the error is due to too many requests
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
        await handleResendVerificationEmail(); // Retry
      }
    }
  };

  return (
    <div>
      <form style={formStyle} onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email"
            className="input-field"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <div style={errorStyle}>{emailError}</div>}
          {emailError && !emailError.includes('Invalid email or password') && !auth.currentUser?.emailVerified && (
            <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
              {' '}
              Didn't receive an email?{' '}
              <span
                style={{ color: '#003A49', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleResendVerificationEmail}
              >
                Resend verification email
              </span>
            </div>
          )}
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Your password"
            className="input-field"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <div style={errorStyle}>{passwordError}</div>}
        </div>
        <div>
          <Link to="/forgot-password" style={forgotPasswordStyle}>
            <p style={{ color: '#000000', fontSize: '16px' }}>Forgot Password?</p>
          </Link>
        </div>
        <button type="submit" style={buttonStyle}>
          Sign In
        </button>
        <div>
          <p style={registerLinkStyle}>
            Don't have an account?{' '}
            <span
              style={registerLinkHoverStyle}
              onClick={switchToRegister}
            >
              Register Here
            </span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default SignIn;
