import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import SignOutButton from '../components/SignOutButton';

const formStyle = {
  maxWidth: "400px",
  margin: "0 auto",
  paddingTop: "25px",
  borderRadius: "5px",
  textAlign: "center",
};

const labelStyle = {
  display: "block",
  textAlign: "left",
  marginTop: "10px",
};

const buttonStyle = {
  width: "100%",
  padding: "10px",
  backgroundColor: "#003A49",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const errorStyle = {
  color: "red",
  fontSize: "0.8em",
  textAlign: "left",
};

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string().min(6, "Password must be at least 6 characters").required("New Password is required"),
});

function ChangePassword() {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSubmit = async (values) => {
    try {
      const credential = EmailAuthProvider.credential(user.email, values.currentPassword);

      // Re-authenticate the user
      await reauthenticateWithCredential(user, credential);

      // Update the password if newPassword is provided
      if (values.newPassword) {
        await updatePassword(user, values.newPassword);
      }

      setSuccessMessage("Password updated successfully");
      setErrorMessage("");
      navigate("/council-account-details");
    } catch (error) {
      console.error("Error updating password: ", error.message);
      setErrorMessage("Password update failed. Please check your current password and try again.");
      setSuccessMessage("");
    }
  };

  const handleSignOut = () => {
    navigate('/skipspace-portal');
  };

  return (
    <div style={{ minHeight: "60vh" }}>
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
        <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="change-password-page-container">
      <div className="back-to-dashboard">
        <Link className="text-underline" to="/council-account-details">
          <p>
            {" "}
            <span style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </span>
            Back to Account Details
          </p>
        </Link>
      </div>
      <div>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange }) => (
            <Form style={formStyle}>
              <div>
                <label htmlFor="currentPassword" style={labelStyle}>
                  Current Password
                </label>
                <Field
                  type={showCurrentPassword ? "text" : "password"}
                  name="currentPassword"
                  className="registerInputStyle"
                  placeholder="Current Password"
                  onChange={handleChange}
                />
                <FontAwesomeIcon
                  icon={showCurrentPassword ? faEye : faEyeSlash}
                  onClick={handleToggleCurrentPassword}
                  style={{ cursor: "pointer", marginLeft: "-30px", position:'absolute', marginTop:'15px', zIndex: "1" }}
                />
                <ErrorMessage
                  name="currentPassword"
                  component="div"
                  style={errorStyle}
                />
              </div>

              <div>
                <label htmlFor="newPassword" style={labelStyle}>
                  New Password
                </label>
                <Field
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  className="registerInputStyle"
                  placeholder="New Password"
                  onChange={handleChange}
                />
                <FontAwesomeIcon
                  icon={showNewPassword ? faEye : faEyeSlash}
                  onClick={handleToggleNewPassword}
                  style={{ cursor: "pointer", marginLeft: "-30px", position:'absolute', marginTop:'15px', zIndex: "1" }}
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  style={errorStyle}
                />
              </div>

              <button type="submit" style={buttonStyle}>
                Update Password
              </button>

              {successMessage && (
                <div style={{ color: "green" }}>{successMessage}</div>
              )}

              {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      </div>
    </div>
  );
}

export default ChangePassword;
