import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import SignOutButton from "../components/SignOutButton";
import { db } from "../components/firebase";

const auth = getAuth();

// Utility function to fetch council data by council_name
const fetchCouncilDataByName = async (councilName) => {
  const councilDocRef = doc(db, "councils", councilName);
  const councilDocSnapshot = await getDoc(councilDocRef);

  if (councilDocSnapshot.exists()) {
    const data = councilDocSnapshot.data();
    return data.council_name;
  }

  return null;
};

const CouncilPortal = () => {
  const navigate = useNavigate();
  const [councilName, setCouncilName] = useState("");
  const [vouchersIssued, setVouchersIssued] = useState(0);
  const [vouchersUsed, setVouchersUsed] = useState(0);  
  

  const handleSignOut = () => {
    navigate("/skipspace-portal");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if the user is already signed in
        const user = auth.currentUser;

        if (!user) {
          // If there is no user, check the sessionStorage for saved user data
          const storedUserString = sessionStorage.getItem("authUser");
          if (storedUserString) {
            onAuthStateChanged(auth, async (user) => {
              if (user) {
                // User found in sessionStorage, set the current user
                const councilNameValue = await fetchCouncilDataByName(user.displayName);
                if (councilNameValue) {
                  setCouncilName(councilNameValue);
                }
              } else {
                // If there is no user in the sessionStorage, redirect to council page
                navigate("/skipspace-portal");
              }
            });
          } else {
            // If there is no user in the sessionStorage, redirect to council page
            navigate("/skipspace-portal");
          }
        } else {
          // If the user is signed in, fetch the council data
          const councilNameValue = await fetchCouncilDataByName(user.displayName);
          if (councilNameValue) {
            setCouncilName(councilNameValue);
          }

          // Save user data in sessionStorage
          const userData = {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
          };
          sessionStorage.setItem("authUser", JSON.stringify(userData));
        }
      } catch (error) {
        console.error("Error fetching council data:", error);
      }
    };

    fetchData();
  }, [navigate]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const councilNameValue = await fetchCouncilDataByName(
          auth.currentUser.displayName
        );
        if (councilNameValue) {
          setCouncilName(councilNameValue);

          // Now that we have the council name, let's fetch voucher data
          const vouchersRef = collection(db, "vouchers");

          const vouchersQuery = query(
            vouchersRef,
            where("local_auth_issue", "==", councilNameValue),
          );

          const querySnapshot = await getDocs(vouchersQuery);
          setVouchersIssued(querySnapshot.size);

          // Now fetch vouchers used count
          const vouchersUsedQuery = query(
            vouchersRef,
            where("local_auth_issue", "==", councilNameValue), 
            where("voucher_used", "==", true)
          );

          const usedQuerySnapshot = await getDocs(vouchersUsedQuery);
          setVouchersUsed(usedQuerySnapshot.size);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.currentUser]);


  const responsiveButtonStyle = {
    width: "100%", // Use full width on small screens
  };

  return (
    <div>
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
          <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="portal-dashboard-main">
          <h1 className="welcome-council">Welcome {councilName}</h1>
          <div className="button-container">
            <Link
              to="/council-account-details"
              style={{ ...responsiveButtonStyle }}
            >
              <button className="portal-button">User Account Details</button>
            </Link>
            <Link to="/skip-site-address" style={{ ...responsiveButtonStyle }}>
              <button className="portal-button">Manage Site Address</button>
            </Link>
            <Link
              to="/skip-company-details"
              style={{ ...responsiveButtonStyle }}
            >
              <button className="portal-button">
                Skip Company Details
              </button>
            </Link>
            <h1 className="vouchers-issued" >Vouchers Issued: {vouchersIssued}</h1>
            <h1 className="vouchers-used" >Vouchers Used: {vouchersUsed}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouncilPortal;
/*
            <Link to="/link-skip-company" style={{ ...responsiveButtonStyle }}>
              <button className="portal-button">Link a Skip Company</button>
            </Link>
*/