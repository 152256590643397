import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import HomeImage from "../images/Homepage/Home-image-new.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";

function Home() {
  return (
    <div>
      <Header />
      <div className="custom-container">
        <div className="text-column">
          <h1 style={{ paddingBottom: "25px" }}>
            Revolutionising Waste Management
          </h1>
          <p>
            The SkipSpace app is custom-designed to reinforce the efforts of
            local authorities in combatting the costly and tiresome issue of
            illegal waste disposal within our boroughs.
          </p>
          <p>
            This innovative platform plays a pivotal role in connecting councils
            with repeat or potential offenders of fly-tipping, while presenting
            a sustainable and responsible substitute to illegal dumping, by
            using dedicated waste site locations.
          </p>
          <Link to="/our-impact">
            <p
              className="text-underline"
              style={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                textDecorationSkipInk: "none",
                fontWeight: "bold",
                color: "#000000",
                paddingTop: "25px",
              }}
            >
              Our Impact <FontAwesomeIcon icon={faArrowRight} />
            </p>
          </Link>
        </div>
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          <img
            className="home-image-screen"
            src={HomeImage}
            alt="Splash screen and Homepage"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
